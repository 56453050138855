var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":_vm.editedItem.bylist == '1' ||
        (_vm.editedItem.bylist == '0' && _vm.depot.NbRack > 0)
          ? '1600px'
          : '700px',"persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),(_vm.progress)?_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"width":7,"color":"purple"}})],1):_vm._e(),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"blue darken-1","disabled":_vm.save_disable,"small":""},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")]),_c('v-btn',{attrs:{"color":"blue darken-1","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Fermer ")])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_vm._v(" Produit : "+_vm._s(_vm.item.label)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_vm._v(" Depot Source: "+_vm._s(_vm.mvm.depot_from_name)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_vm._v(" Depot Destination : "+_vm._s(_vm.item.depot_name)+" ")]),(
                  _vm.$store.state.auth.includes('01027') || _vm.$store.state.isadmin
                )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_vm._v(" PU : "+_vm._s(_vm.item.pmp)+" ")]):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{ref:"qte",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","label":'Quantité' +
                    (_vm.editedItem.unit ? ' (' + _vm.editedItem.unit + ')' : ''),"rules":[
                    function (v) { return !!v || 'Quantité obligatoire'; },
                    function (v) { return v > 0 || 'Valeur incorrecte '; },
                    function (v) { return parseFloat(v) <= parseFloat(_vm.qte_max) ||
                      'Erreur Stock ' + _vm.qte_max; } ],"hide-spin-buttons":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.qte),callback:function ($$v) {_vm.$set(_vm.editedItem, "qte", _vm._n($$v))},expression:"editedItem.qte"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"12"}},[_c('v-text-field',{ref:"comment",attrs:{"autocomplete":"off","dense":"","label":"Commentaire"},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1),(
                  (_vm.editedItem.bylist == '1' ||
                    (_vm.editedItem.bylist == '0' && _vm.depot.NbRack > 0)) &&
                  _vm.editedItem.article_id > 0
                )?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"1","md":"11"}},[_c('listitems',{key:_vm.kflist,attrs:{"list":_vm.flist,"headers":_vm.editedItem.bylist == '1'
                          ? _vm.dispo_headers
                          : _vm.boxheaders_d,"title":_vm.depot.label,"master":true,"add":false,"del":false,"Update":false,"showedit":true,"Total":true,"print":true,"exp_excel":true,"imp_excel":false,"multiple":true,"laoding":_vm.productload,"ipg":5},on:{"selected_rows":_vm.selected_left_change}})],1),(_vm.editedItem.depot_id)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-btn',{staticClass:"my-2",attrs:{"fab":"","color":"blue darken-1","small":"","disabled":_vm.selected_left.length == 0},on:{"click":_vm.add_product}},[_vm._v(" > ")]),(_vm.editedItem.depot_id)?_c('v-btn',{staticClass:"my-2",attrs:{"fab":"","color":"blue darken-1","small":"","disabled":_vm.selected_right.length == 0},on:{"click":_vm.del_product}},[_c('span',{domProps:{"innerHTML":_vm._s('<')}})]):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(
                  (_vm.editedItem.bylist == '1' ||
                    (_vm.editedItem.bylist == '0' && _vm.depot.NbRack > 0)) &&
                  _vm.editedItem.depot_id
                )?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"6"}},[_c('listitems',{key:_vm.frml,attrs:{"list":_vm.form_list,"headers":_vm.editedItem.bylist == '1' ? _vm.article_headers : _vm.boxheaders_s,"title":_vm.dest.label,"master":true,"add":false,"del":false,"Update":true,"showedit":true,"showstd":true,"Total":true,"print":true,"exp_excel":true,"imp_excel":false,"multiple":true,"laoding":_vm.listload,"ipg":5},on:{"selected_rows":_vm.selected_right_change,"add":_vm.add_item,"delete":_vm.del_item}})],1):_vm._e()],1)],1)],1),_c('v-card-actions')],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }